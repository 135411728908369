<template>
	<div id="miniGoodsEdit">	
		<page-goods-edit :is-shop="0"></page-goods-edit>
	</div>

</template>

<script>
import pageGoodsEdit from '@/components/layout/goods/page-goods-edit.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default {
	components: {
		pageGoodsEdit
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/goods/list'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})
	}
}
</script>

<style>
	
</style>
